<template>
  <div class="clock">
    <span class="timestamp caption ems-accent-secondary--text">
      現在時間：{{ nowDateTime }}
    </span>
    <!-- <div class="clock__hours">
      <span class="clock__hourtime" v-text="hourtime"></span>
      <span v-text="hours"></span>
    </div>
    <div class="clock__minutes" v-text="minutes"></div>
    <div class="clock__seconds" v-text="seconds"></div> -->
  </div>
</template>

<script>
// import { SECOND, HOUR, getHourTime, getZeroPad } from './Filters'
const SECOND = 1000
// const HOUR = 12
// function getHourTime (h) {
//   return h >= 12 ? 'PM' : 'AM'
// }
// function getZeroPad (n) {
//   return (parseInt(n, 10) >= 10 ? '' : '0') + n
// }
export default {
  props: {},
  data() {
    return {
      nowDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      hours: 0,
      minutes: 0,
      seconds: 0,
      hourtime: ''
    }
  },
  mounted() {
    this.$options.timer = window.setTimeout(this.updateDateTime, SECOND)
  },
  beforeDestroy() {
    window.clearTimeout(this.$options.timer)
  },
  methods: {
    updateDateTime() {
      // const now = new Date()
      this.nowDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
      // this.hours = now.getHours()
      // this.minutes = getZeroPad(now.getMinutes())
      // this.seconds = getZeroPad(now.getSeconds())
      // this.hourtime = getHourTime(this.hours)
      // this.hours = this.hours % HOUR || HOUR
      this.$options.timer = window.setTimeout(this.updateDateTime, SECOND)
    }
  }
}
</script>

<style scoped>
.clock {
  /* background: #fff;
  border: 0.3rem solid #fff;
  border-radius: 0.5rem; */
  display: inline-block;
  /* margin-bottom: 1em; */
}
/*
.clock__hours,
.clock__minutes,
.clock__seconds {
  background: linear-gradient(to bottom, #26303b 50%, #2c3540 50%);
  display: inline-block;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 3rem;
  font-weight: 300;
  padding: 0.5rem 1rem;
  text-align: center;
  position: relative;
}
.clock__hours {
  border-right: 0.15rem solid #fff;
  border-radius: 0.5rem 0 0 0.5rem;
}
.clock__minutes {
  border-right: 0.15rem solid #fff;
}
.clock__seconds {
  border-radius: 0 0.5rem 0.5rem 0;
}
.clock__hourtime {
  font-size: 1rem;
  position: absolute;
  top: 2px;
  left: 8px;
} */
</style>
